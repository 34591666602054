.img-logo {
    width: 140px;
    height: 70px;

}

@media(min-width:992px){
    .align-navbar{
        justify-content: end;
    }
}
.mb-fixed {
    margin-bottom: 50px;
}
@media(max-width: 992px) {
    .container-img-navbar{
        width: 200px;
    }
    .li-navbar-responsive{
        visibility: hidden;
        position: absolute;
    }
}
