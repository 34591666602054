
.color1 {
    color: #f0f2f5;
}
.bg-color1 {
    background-color: #f0f2f5;
}
.color2 {
    color: #97cb52;
}
.bg-color2 {
    background-color: #97cb52;
}
