/* .container-h {
  height: 1655px;
} */
.img-fondo {
  background-image: url("../../img/nosotros/paisaje4.jpg");
  /* background-repeat: no-repeat; */
  background-size: cover;
  background-position: center;
  width: 100wh;
  height: 100%;
  /* filter: invert(10%); */
  /* filter: opacity(5%); */
}
.parte-1 {
  height: 650px;
}
.font-t {
  /* font-family: 'Dancing Script', cursive; */
  font-family: "Lobster", cursive;
  font-size: 140px;
  color: rgb(41, 51, 41);
}
.font-st {
  font-family: "Times New Roman", Times, serif;
  font-size: 56px;
  color: rgb(41, 51, 41);
}
.font-d {
  /* font-family: 'Dancing Script', cursive; */
  font-family: "Lobster", cursive;
  font-size: 28px;
  color: rgb(20, 0, 0);
}

.bg-parte-2 {
  background-color: rgb(255, 255, 255);
}
.img-fondo-row-2 {
  background-image: url("../../img/nosotros/plato-muestra.jpg");
  background-size: cover;
  background-position: center;
  width: 75wh;
  height: 76vh;
}

.font-t-col-2 {
  font-family: "Times New Roman", Times, serif;
  color: #119448;
}
.font-st-col-2 {
  font-family: "Times New Roman", Times, serif;
  font-size: 30px;
  font-weight: bold;
}
.font-d-col-2 {
  color: #3f4752;
  font-size: 20px;
  font-family: "Times New Roman", Times, serif;
}
.font-p-col-2 {
  color: #3f4752;
  font-size: 28px;
  font-family: "Lobster", cursive;
}

.parte-3 {
  padding-top: 75px;
  padding-bottom: 75px;
}
.font-t-3 {
  font-family: "Lobster", cursive;
  font-size: 46px;
  font-weight: bold;
  color: rgb(255, 255, 255);
}
.font-d-3 {
  font-family: "Times New Roman", Times, serif;
  font-size: 20px;
  color: rgb(255, 255, 255);
  padding-left: 50px;
  padding-right: 50px;
}
.wa {
  width: 50px;
  display: inline-block;
}
@media (max-width: 1200px) {
  /* .container-h {
    height: 1743px;
  } */
  .font-d {
	font-size: 24px;
  }
}
@media (max-width: 992px) {
  /* .container-h {
    height: 1560px;
  } */
  .font-d {
	font-size: 22px;
  }
  .col-2{
	  padding-top: 50px;
	  padding-bottom: 50px;
  }
  .font-d-col-2{
	  margin-bottom: 0px;
  }
  .parte-3-col-2{
	  padding-top: 50px;
  }
  .font-t-3 {
    font-family: "Lobster", cursive;
    font-size: 36px;
    font-weight: bold;
    color: rgb(255, 255, 255);
  }
  .font-d-3 {
    font-family: "Times New Roman", Times, serif;
    font-size: 18px;
    color: rgb(255, 255, 255);
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 768px) {
  /* .container-h {
    height: 1727px;
  } */
  .font-d {
	font-size: 22px;
  }
  .img-fondo-row-2 {
    width: 65wh;
    height: 66vh;
  }
  .font-t {
    font-size: 100px;
  }
  .col-2 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .font-t-col-2 {
    font-family: "Times New Roman", Times, serif;
    color: #119448;
    text-align: initial;
  }
  .font-st-col-2 {
    font-family: "Times New Roman", Times, serif;
    font-size: 30px;
    text-align: initial;
  }
  .font-d-col-2 {
    color: #3f4752;
    font-size: 20px;
    font-family: "Times New Roman", Times, serif;
    text-align: initial;
  }
  .font-p-col-2 {
    color: #3f4752;
    font-size: 28px;
    font-family: "Lobster", cursive;
    text-align: initial;
  }
}
@media (max-width: 576px) {
  .font-t {
    font-size: 70px;
  }
  .font-st {
    font-size: 40px;
  }
  .font-d {
    font-size: 18px;
  }
}

/* body {
	background: #DCDFD9;
    font-family: 'Readex Pro', sans-serif;
	color: #1b3231;
}
.text-t{
    font-size: 60px;
}
.hero {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 35vh;
	font-size: 36px;
	text-transform: uppercase;
}

.container {
	width: 90%;
	max-width: 1200px;
	margin: 100px auto;
	display: flex;
	flex-direction: column;
	position: relative;
}

.container::after {
	content: "";
	width: 2px;
	height: 100%;
	background: #1b3231;
	position: absolute;
	top: 0;
	left: calc(50% - 1px);
	z-index: 1;
}

.evento {
	display: flex;
	justify-content: space-between;
	margin-bottom: 200px;
	position: relative;
}

.evento::after {
	content: "";
	display: block;
	width: 14px;
	height: 14px;
	border-radius: 100px;
	background: #DCDFD9;
	border: 2px solid #1b3231;
	position: absolute;
	z-index: 2;
	top: calc(50% - 9px);
	left: calc(50% - 9px);
}

.fecha {
	display: flex;
	align-items: center;
	justify-content: start;
	width: calc(42% + 20px);
	font-size: 32px;
	font-weight: normal;
}

.foto {
	width: 42%;
	border: 10px solid #fff;
	position: relative;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.foto::after {
	content: "";
	display: block;
	border: 20px solid transparent;
	border-left: 20px solid #fff;
	position: absolute;
	right: -50px;
	top: calc(50% - 20px);
}

.foto img {
	width: 100%;
	vertical-align: top;
}

.evento:nth-child(even) {
	flex-direction: row-reverse;
}

.evento:nth-child(even) .fecha {
	justify-content: end;
}

.evento:nth-child(even) .foto::after {
	content: "";
	border: 20px solid transparent;
	border-right: 20px solid #fff;
	right: initial;
	left: -50px;
}

*/
