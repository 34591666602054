.mt-navbar{
  margin-top: 90px;
}

.btn-pedido {
  border-radius: 0px;
}
.btn-pedido-text {
  color: white;
  text-decoration: none;
}
.h-img-italia {
  height: 160px;
}

.carousel-inicial{
  width: 100%;
  height: 720px;
}
/* .aaa{
  background-size: cover;
  background-position: center;
} */
.img-carousel-inicial{
  height: 85vh;
  width: 100%;
  object-fit: fill;
}

.t-bienvenido {
  font-family: sans-serif;
  font-size: 32px;
  font-style: normal;
}
.p-bienvenido {
  font-family: sans-serif;
  font-size: 24px;
  font-style: normal;
}
.py-65 {
  padding-top: 65px;
  padding-bottom: 65px;
}
.pb-24 {
  padding-bottom: 24vh;
}
.img-bienvenido{
  width: 70%;
}
.wa {
  width: 50px;
  display: inline-block;
}

.link-download {
  background-color: #f6f6f6;
  color: black;
}
.link-download-div-text {
  color: black;
  text-decoration: none;
}
.div-contacto {
  background-color: #f6f6f6;
}
.btn-wsp {
  background-color: #455a64;
  border-radius: 7px;
  color: transparent;
  display: flex;
  flex: 1 1 auto;
  width: 285px;
}
.btn-wsp-icon {
  width: 35px;
  height: 35px;
}
.btn-wsp-text {
  color: white;
  font-size: 16px;
  font-weight: bolder;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  align-self: center;
}

.btn-wsp-span {
  width: 50px;
}

.tit-secon {
  font-size: 22px;
  font-weight: bolder;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  font-style: normal;
  align-self: center;
}
.email-text {
  font-size: 19px;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.footer-cont {
  background-color: #161616;
  padding-top: 56px;
  padding-bottom: 56px;
}
.footer-text {
  color: #a9a9a9;
}
.div-contacto {
  padding-bottom: 30px;
}

.item-carousel-prod {
  height: 600px;
  width: 450px;
}

.img-carousel-1 {
  width: 100%;
  height: 100%;
}
.img-carousel-2 {
  width: 100%;
  height: 100%;
  filter: saturate(2%);
}

.item-carousel-clientes {
  width: 70px;
  height: 50px;
}

@media (max-width: 992px) {
  .img-bienvenido{
    width: 80%;
  }
  .carousel-inicial{
    height: 500px;
  }
  .img-carousel-inicial{
    height: 500px;
    width: 100%;
  }
  .item-carousel-prod{
    height: 500px;
    width: 375px;
  }

  .centrar-tit {
    text-align: center;
  }
  .pt-media-2{
    padding-top: 10px;
  }
  .div-contacto {
    padding-bottom: 50px;
  }
  .h-img-italia {
    height: 100px;
  }
  .py-65{
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media (max-width: 768px) {
  .carousel-inicial{
    height: 300px;
  }
  .img-carousel-inicial{
    height: 300px;
    width: 100%;
  }
  .item-carousel-prod{
    height: 300px;
    width: 200px;
  }

}
@media(max-width: 576px) {
  .img-carousel-1 {
    width: 70%;
    height: 70%;
  }
  .p-bienvenido {
    font-family: sans-serif;
    font-size: 20px;
    font-style: normal;
  }
}