.mt-navbar {
  margin-top: 106px;
}
.img-inicial {
  width: 100%;
  height: 900px;
  object-fit: fill;
}

/** Navbar Productos **/
.py-navbar-secundario {
  padding-top: 20px;
  padding-bottom: 10px;
}

.navbar-secundario {
  position: fixed;
  z-index: 100;
  top: 6rem;
  width: 100%;
  background: -webkit-radial-gradient(top, #8c8c8c, #e5e5e5);
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
}
.nav-prod-text-scroll {
  color: white;
  font-weight: 400;
}
.nav-prod-text-scroll:hover {
  color: #119448;
}
.text-size {
  font-size: 24px;
}
.navbar-prod-text {
  color: #3f4752;
  font-weight: 400;
}
.navbar-prod-text:hover {
  color: #119448;
  font-weight: 400;
}

/* Titulo de cada categoria */
.text-titulo {
  font-size: 46px;
  font-family: Georgia, "Times New Roman", Times, serif;
  color: #119448;
}
.text-descripcion {
  /* font-size: 20px; */
  /* font-family:'Edu NSW ACT Foundation', cursive; */
  font-family: "Taviraj", serif;
  font-size: 36px;
  color: black;
}

.card-product {
  transition-duration: 300ms;
}
.card-product:hover {
  transform: scale(120%);
}
.img-product {
  filter: saturate(85%);
}
.img-product:hover {
  filter: saturate(140%);
}
.img-width {
  width: 10rem;
}
.text-products {
  color: #119448;
}
.text-description {
  color: #3f4752;
}

@media (max-width: 992px) {
  .img-inicial {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}

@media (max-width: 576px) {
  .nav-prod-text-scroll {
    font-size: 16px;
  }
  .navbar-prod-text{
    font-size: 16px;
  }
  .text-descripcion{
    font-size: 20px;
  }
}

@media (max-width: 400px) {
  .nav-prod-text-scroll {
    font-size: 14px;
  }
  .navbar-prod-text{
    font-size: 14px;
  }
  
}
