body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mt-navbar{
  margin-top: 90px;
}

.btn-pedido {
  border-radius: 0px;
}
.btn-pedido-text {
  color: white;
  text-decoration: none;
}
.h-img-italia {
  height: 160px;
}

.carousel-inicial{
  width: 100%;
  height: 720px;
}
/* .aaa{
  background-size: cover;
  background-position: center;
} */
.img-carousel-inicial{
  height: 85vh;
  width: 100%;
  object-fit: fill;
}

.t-bienvenido {
  font-family: sans-serif;
  font-size: 32px;
  font-style: normal;
}
.p-bienvenido {
  font-family: sans-serif;
  font-size: 24px;
  font-style: normal;
}
.py-65 {
  padding-top: 65px;
  padding-bottom: 65px;
}
.pb-24 {
  padding-bottom: 24vh;
}
.img-bienvenido{
  width: 70%;
}
.wa {
  width: 50px;
  display: inline-block;
}

.link-download {
  background-color: #f6f6f6;
  color: black;
}
.link-download-div-text {
  color: black;
  text-decoration: none;
}
.div-contacto {
  background-color: #f6f6f6;
}
.btn-wsp {
  background-color: #455a64;
  border-radius: 7px;
  color: transparent;
  display: flex;
  flex: 1 1 auto;
  width: 285px;
}
.btn-wsp-icon {
  width: 35px;
  height: 35px;
}
.btn-wsp-text {
  color: white;
  font-size: 16px;
  font-weight: bolder;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  align-self: center;
}

.btn-wsp-span {
  width: 50px;
}

.tit-secon {
  font-size: 22px;
  font-weight: bolder;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  font-style: normal;
  align-self: center;
}
.email-text {
  font-size: 19px;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.footer-cont {
  background-color: #161616;
  padding-top: 56px;
  padding-bottom: 56px;
}
.footer-text {
  color: #a9a9a9;
}
.div-contacto {
  padding-bottom: 30px;
}

.item-carousel-prod {
  height: 600px;
  width: 450px;
}

.img-carousel-1 {
  width: 100%;
  height: 100%;
}
.img-carousel-2 {
  width: 100%;
  height: 100%;
  -webkit-filter: saturate(2%);
          filter: saturate(2%);
}

.item-carousel-clientes {
  width: 70px;
  height: 50px;
}

@media (max-width: 992px) {
  .img-bienvenido{
    width: 80%;
  }
  .carousel-inicial{
    height: 500px;
  }
  .img-carousel-inicial{
    height: 500px;
    width: 100%;
  }
  .item-carousel-prod{
    height: 500px;
    width: 375px;
  }

  .centrar-tit {
    text-align: center;
  }
  .pt-media-2{
    padding-top: 10px;
  }
  .div-contacto {
    padding-bottom: 50px;
  }
  .h-img-italia {
    height: 100px;
  }
  .py-65{
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media (max-width: 768px) {
  .carousel-inicial{
    height: 300px;
  }
  .img-carousel-inicial{
    height: 300px;
    width: 100%;
  }
  .item-carousel-prod{
    height: 300px;
    width: 200px;
  }

}
@media(max-width: 576px) {
  .img-carousel-1 {
    width: 70%;
    height: 70%;
  }
  .p-bienvenido {
    font-family: sans-serif;
    font-size: 20px;
    font-style: normal;
  }
}

.color1 {
    color: #f0f2f5;
}
.bg-color1 {
    background-color: #f0f2f5;
}
.color2 {
    color: #97cb52;
}
.bg-color2 {
    background-color: #97cb52;
}

.img-logo {
    width: 140px;
    height: 70px;

}

@media(min-width:992px){
    .align-navbar{
        justify-content: end;
    }
}
.mb-fixed {
    margin-bottom: 50px;
}
@media(max-width: 992px) {
    .container-img-navbar{
        width: 200px;
    }
    .li-navbar-responsive{
        visibility: hidden;
        position: absolute;
    }
}

.mt-navbar {
  margin-top: 106px;
}
.img-inicial {
  width: 100%;
  height: 900px;
  object-fit: fill;
}

/** Navbar Productos **/
.py-navbar-secundario {
  padding-top: 20px;
  padding-bottom: 10px;
}

.navbar-secundario {
  position: fixed;
  z-index: 100;
  top: 6rem;
  width: 100%;
  background: -webkit-radial-gradient(top, #8c8c8c, #e5e5e5);
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
}
.nav-prod-text-scroll {
  color: white;
  font-weight: 400;
}
.nav-prod-text-scroll:hover {
  color: #119448;
}
.text-size {
  font-size: 24px;
}
.navbar-prod-text {
  color: #3f4752;
  font-weight: 400;
}
.navbar-prod-text:hover {
  color: #119448;
  font-weight: 400;
}

/* Titulo de cada categoria */
.text-titulo {
  font-size: 46px;
  font-family: Georgia, "Times New Roman", Times, serif;
  color: #119448;
}
.text-descripcion {
  /* font-size: 20px; */
  /* font-family:'Edu NSW ACT Foundation', cursive; */
  font-family: "Taviraj", serif;
  font-size: 36px;
  color: black;
}

.card-product {
  transition-duration: 300ms;
}
.card-product:hover {
  -webkit-transform: scale(120%);
          transform: scale(120%);
}
.img-product {
  -webkit-filter: saturate(85%);
          filter: saturate(85%);
}
.img-product:hover {
  -webkit-filter: saturate(140%);
          filter: saturate(140%);
}
.img-width {
  width: 10rem;
}
.text-products {
  color: #119448;
}
.text-description {
  color: #3f4752;
}

@media (max-width: 992px) {
  .img-inicial {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}

@media (max-width: 576px) {
  .nav-prod-text-scroll {
    font-size: 16px;
  }
  .navbar-prod-text{
    font-size: 16px;
  }
  .text-descripcion{
    font-size: 20px;
  }
}

@media (max-width: 400px) {
  .nav-prod-text-scroll {
    font-size: 14px;
  }
  .navbar-prod-text{
    font-size: 14px;
  }
  
}

.ass {
  -webkit-filter: invert(25%);
          filter: invert(25%);
  height: 1050px;
}
.text-img {
  position: absolute;
  top: 10rem;
}
.text-img-t {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 56px;
  color: rgb(255, 255, 255);
  font-weight: 700;
  padding-bottom: 20px;
}
.text-img-d {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 22px;
  color: white;
}

.form-img {
  position: absolute;
  top: 30rem;
}

.text-form {
  font-weight: 700;
  font-size: 16px;
  color: white;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.col-form {
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.525);
  border-radius: 8px;
}

@media (max-width: 768px) {
  .text-img-t {
    font-size: 42px;
  }
  .text-img-d {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
    .text-img-t {
        font-size:38px;
    }
    .text-img-d {
        font-size: 14px;
    }
}
/* .container-h {
  height: 1655px;
} */
.img-fondo {
  background-image: url(/static/media/paisaje4.978ec8d0.jpg);
  /* background-repeat: no-repeat; */
  background-size: cover;
  background-position: center;
  width: 100wh;
  height: 100%;
  /* filter: invert(10%); */
  /* filter: opacity(5%); */
}
.parte-1 {
  height: 650px;
}
.font-t {
  /* font-family: 'Dancing Script', cursive; */
  font-family: "Lobster", cursive;
  font-size: 140px;
  color: rgb(41, 51, 41);
}
.font-st {
  font-family: "Times New Roman", Times, serif;
  font-size: 56px;
  color: rgb(41, 51, 41);
}
.font-d {
  /* font-family: 'Dancing Script', cursive; */
  font-family: "Lobster", cursive;
  font-size: 28px;
  color: rgb(20, 0, 0);
}

.bg-parte-2 {
  background-color: rgb(255, 255, 255);
}
.img-fondo-row-2 {
  background-image: url(/static/media/plato-muestra.3e376807.jpg);
  background-size: cover;
  background-position: center;
  width: 75wh;
  height: 76vh;
}

.font-t-col-2 {
  font-family: "Times New Roman", Times, serif;
  color: #119448;
}
.font-st-col-2 {
  font-family: "Times New Roman", Times, serif;
  font-size: 30px;
  font-weight: bold;
}
.font-d-col-2 {
  color: #3f4752;
  font-size: 20px;
  font-family: "Times New Roman", Times, serif;
}
.font-p-col-2 {
  color: #3f4752;
  font-size: 28px;
  font-family: "Lobster", cursive;
}

.parte-3 {
  padding-top: 75px;
  padding-bottom: 75px;
}
.font-t-3 {
  font-family: "Lobster", cursive;
  font-size: 46px;
  font-weight: bold;
  color: rgb(255, 255, 255);
}
.font-d-3 {
  font-family: "Times New Roman", Times, serif;
  font-size: 20px;
  color: rgb(255, 255, 255);
  padding-left: 50px;
  padding-right: 50px;
}
.wa {
  width: 50px;
  display: inline-block;
}
@media (max-width: 1200px) {
  /* .container-h {
    height: 1743px;
  } */
  .font-d {
	font-size: 24px;
  }
}
@media (max-width: 992px) {
  /* .container-h {
    height: 1560px;
  } */
  .font-d {
	font-size: 22px;
  }
  .col-2{
	  padding-top: 50px;
	  padding-bottom: 50px;
  }
  .font-d-col-2{
	  margin-bottom: 0px;
  }
  .parte-3-col-2{
	  padding-top: 50px;
  }
  .font-t-3 {
    font-family: "Lobster", cursive;
    font-size: 36px;
    font-weight: bold;
    color: rgb(255, 255, 255);
  }
  .font-d-3 {
    font-family: "Times New Roman", Times, serif;
    font-size: 18px;
    color: rgb(255, 255, 255);
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 768px) {
  /* .container-h {
    height: 1727px;
  } */
  .font-d {
	font-size: 22px;
  }
  .img-fondo-row-2 {
    width: 65wh;
    height: 66vh;
  }
  .font-t {
    font-size: 100px;
  }
  .col-2 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .font-t-col-2 {
    font-family: "Times New Roman", Times, serif;
    color: #119448;
    text-align: initial;
  }
  .font-st-col-2 {
    font-family: "Times New Roman", Times, serif;
    font-size: 30px;
    text-align: initial;
  }
  .font-d-col-2 {
    color: #3f4752;
    font-size: 20px;
    font-family: "Times New Roman", Times, serif;
    text-align: initial;
  }
  .font-p-col-2 {
    color: #3f4752;
    font-size: 28px;
    font-family: "Lobster", cursive;
    text-align: initial;
  }
}
@media (max-width: 576px) {
  .font-t {
    font-size: 70px;
  }
  .font-st {
    font-size: 40px;
  }
  .font-d {
    font-size: 18px;
  }
}

/* body {
	background: #DCDFD9;
    font-family: 'Readex Pro', sans-serif;
	color: #1b3231;
}
.text-t{
    font-size: 60px;
}
.hero {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 35vh;
	font-size: 36px;
	text-transform: uppercase;
}

.container {
	width: 90%;
	max-width: 1200px;
	margin: 100px auto;
	display: flex;
	flex-direction: column;
	position: relative;
}

.container::after {
	content: "";
	width: 2px;
	height: 100%;
	background: #1b3231;
	position: absolute;
	top: 0;
	left: calc(50% - 1px);
	z-index: 1;
}

.evento {
	display: flex;
	justify-content: space-between;
	margin-bottom: 200px;
	position: relative;
}

.evento::after {
	content: "";
	display: block;
	width: 14px;
	height: 14px;
	border-radius: 100px;
	background: #DCDFD9;
	border: 2px solid #1b3231;
	position: absolute;
	z-index: 2;
	top: calc(50% - 9px);
	left: calc(50% - 9px);
}

.fecha {
	display: flex;
	align-items: center;
	justify-content: start;
	width: calc(42% + 20px);
	font-size: 32px;
	font-weight: normal;
}

.foto {
	width: 42%;
	border: 10px solid #fff;
	position: relative;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.foto::after {
	content: "";
	display: block;
	border: 20px solid transparent;
	border-left: 20px solid #fff;
	position: absolute;
	right: -50px;
	top: calc(50% - 20px);
}

.foto img {
	width: 100%;
	vertical-align: top;
}

.evento:nth-child(even) {
	flex-direction: row-reverse;
}

.evento:nth-child(even) .fecha {
	justify-content: end;
}

.evento:nth-child(even) .foto::after {
	content: "";
	border: 20px solid transparent;
	border-right: 20px solid #fff;
	right: initial;
	left: -50px;
}

*/

